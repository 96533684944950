import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Container, Grid } from '@material-ui/core';

import PageHeader from '../ui/PageHeader';
import CompanyCard from '../components/company/CompanyCard';
import SEO from '../components/layout/seo';

const Empresas = () => {
  const { allDatoCmsCompany } = useStaticQuery(graphql`
    {
      allDatoCmsCompany {
        nodes {
          id
          incompleteData
          companyName
          companyAddress
          companySlug
          companyTel1
          companyTel2
          companyCategory {
            categoryName
          }
          companyLogo {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          companyWebsite
        }
      }
    }
  `);

  const [sortNodes] = useState('nameAsc');

  return (
    <>
      <SEO title="Empresas Alphanorth" />
      <Container maxWidth="md">
        <PageHeader title="Empresas Alphanorth" />
      </Container>
      <Box p={2}>
        <Container>
          <Grid container spacing={3}>
            {allDatoCmsCompany.nodes
              .sort((a, b) => {
                switch (sortNodes) {
                  case 'category':
                    if (
                      a.companyCategory.categoryName <
                      b.companyCategory.categoryName
                    )
                      return -1;
                    if (
                      a.companyCategory.categoryName <
                      b.companyCategory.categoryName
                    )
                      return 1;
                    return 0;

                  case 'nameDesc':
                    if (a.companyName > b.companyName) return -1;
                    if (a.companyName > b.companyName) return 1;
                    return 0;

                  default:
                    if (a.companyName < b.companyName) return -1;
                    if (a.companyName < b.companyName) return 1;
                    return 0;
                }
              })
              .map((company) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                  <CompanyCard node={company} />
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Empresas;
