import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Box, Typography } from '@material-ui/core';

import { Link } from 'gatsby-theme-material-ui';

const CompanyCard = ({ node }) => (
  <StyledCompanyCard>
    <Link to={`/empresas/${node.companySlug}`}>
      <LogoStyled>
        {node.incompleteData ? (
          <div className="text">
            <Typography variant="h2" style={{ fontSize: '2rem' }}>
              {node.companyName.toUpperCase()}
            </Typography>
          </div>
        ) : (
          <Img fluid={node.companyLogo.fluid} />
        )}
      </LogoStyled>
    </Link>
    {/* {node.companyLogo && (
      <LogoStyled>
        
      </LogoStyled>
    )} */}
    {/* <Typography component="small">
      {node.companyCategory.categoryName}
    </Typography>
    <Typography variant="h4" component="h2" paragraph>
      <Link to={`/empresas/${node.companySlug}`}>{node.companyName}</Link>
    </Typography>
    <IconInfo icon={<RoomOutlined />} text={node.companyAddress} />
    <IconInfo icon={<Phone />} text={node.companyTel1} />
    {node.tel2 ? (
      <IconInfo icon={<PhoneOutlined />} text={node.companyTel2} />
    ) : null}
    <IconInfo icon={<Language />} text={node.companyWebsite} />
    <Divider /> */}
  </StyledCompanyCard>
);

const StyledCompanyCard = styled(Box)`
  a:hover {
    text-decoration: none;
  }
`;

const LogoStyled = styled(Box)`
  padding: 2rem;
  color: gray;
  background: white;
  border-radius: 15px;
  transition: all 0.2s ease;

  &:hover {
    color: green;
    text-decoration: none !important;
  }

  .gatsby-image-wrapper,
  .text {
    width: 100%;
    height: 130px;

    img {
      object-fit: contain !important;
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
`;

CompanyCard.propTypes = {
  node: PropTypes.object.isRequired,
};

export default CompanyCard;
